import type { ErrorActions, FirstParameter } from 'shared/types'
const logger = Logger.getLogger({ scope: 'agnostic', method: 'useCustomer' })

export function useCustomer() {
  const errors = {
    login: undefined,
    logout: undefined,
    load: undefined,
    register: undefined,
    changePassword: undefined,
    updateUser: undefined,
    registerFromConfirmation: undefined,
    addAddress: undefined,
    removeAddress: undefined,
    setDefaultAddress: undefined,
    editAddress: undefined,
    orders: undefined,
    forgotPassword: undefined,
    checkResetToken: undefined,
    setNewPassword: undefined
  }
  const error = ref<ErrorActions<typeof errors>>(errors)
  const api = useEcommerce()

  const { session, updateSession, getDefaultCountry, userLoading } = useSession()

  const customer = computed(() => session.value.customer)
  const isAuthenticated = computed(() => session.value?.isRegistered)

  const ordersLoading = ref(true)
  const addressLoading = ref(false)
  const checkedResetToken = ref<{ valid: boolean; expired?: boolean } | undefined>(undefined)

  async function login(params: FirstParameter<typeof api.logIn>) {
    logger.debug('useCustomer.login', params)

    try {
      userLoading.value = true

      const response = await api.logIn(params)
      if (response.ok) {
        updateSession(response.payload)
        error.value = { ...error.value, login: undefined }
      } else {
        error.value = { ...error.value, login: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, login: { __general__: 'error.global.default' } }
      logger.error('useUser.login', err)
    } finally {
      userLoading.value = false
    }
  }

  const logout = async () => {
    logger.debug('useCustomer.logout')

    try {
      const result = await api.logOut()
      if (result.ok && result.payload) {
        updateSession(result.payload)
      }
      error.value = { ...error.value, logout: undefined }
    } catch (err) {
      error.value = { ...error.value, logout: { __general__: 'error.global.default' } }
      logger.error('useUser.logout', err)
    }
  }

  const changePassword = async (params: FirstParameter<typeof api.changePassword>) => {
    logger.debug('useCustomer.changePassword')

    try {
      userLoading.value = true

      const response = await api.changePassword(params)
      if (response.ok) {
        updateSession(response.payload)
        error.value = { ...error.value, changePassword: undefined }
      } else {
        error.value = { ...error.value, changePassword: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, changePassword: { __general__: 'error.global.default' } }
      logger.error('useUser.changePassword', err)
    } finally {
      userLoading.value = false
    }
  }

  const register = async (providedUser: FirstParameter<typeof api.register>) => {
    logger.debug('useCustomer.register', providedUser)

    try {
      userLoading.value = true

      const response = await api.register(providedUser)
      if (response.ok) {
        updateSession(response.payload)
        error.value = { ...error.value, register: undefined }
      } else {
        error.value = { ...error.value, register: response.error }
      }
      return response
    } catch (err) {
      error.value = { ...error.value, register: { __general__: 'error.global.default' } }
      logger.error('useUser.register', err)
    } finally {
      userLoading.value = false
    }
  }

  const registerFromConfirmation = async (params: FirstParameter<typeof api.registerFromConfirmarion>) => {
    logger.debug('useCustomer.registerFromConfirmation', params)

    try {
      userLoading.value = true

      const response = await api.registerFromConfirmarion(params)
      if (response.ok) {
        updateSession(response.payload)
        error.value = { ...error.value, registerFromConfirmation: undefined }
      } else {
        error.value = { ...error.value, registerFromConfirmation: response.error }
      }
      return response
    } catch (err) {
      error.value = { ...error.value, registerFromConfirmation: { __general__: 'error.global.default' } }
      logger.error('useUser.registerFromConfirmation', err)
    } finally {
      userLoading.value = false
    }
  }

  const updateUser = async (params: FirstParameter<typeof api.updateCustomer>) => {
    logger.debug('useCustomer.updateUser', params)

    try {
      userLoading.value = true

      const response = await api.updateCustomer(params)
      if (response.ok) {
        updateSession({ customer: response.payload })
        error.value = { ...error.value, updateUser: undefined }
      } else {
        error.value = { ...error.value, updateUser: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, updateUser: { __general__: 'error.global.default' } }
      logger.error('useUser.updateUser', err)
    } finally {
      userLoading.value = false
    }
  }

  const addAddress = async (params: FirstParameter<typeof api.addCustomerAddress>) => {
    logger.debug('useCustomer.addAddress', params)

    try {
      addressLoading.value = true

      const response = await api.addCustomerAddress(params)
      if (response.ok) {
        updateSession({ customer: response.payload })
        error.value = { ...error.value, addAddress: undefined }
      } else {
        error.value = { ...error.value, addAddress: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, addAddress: { __general__: 'error.global.default' } }
      logger.error('useUser.addAddress', err)
    } finally {
      addressLoading.value = false
    }
  }

  const removeAddress = async (addressId: string) => {
    logger.debug('useCustomer.removeAddress', addressId)

    try {
      addressLoading.value = true

      const response = await api.removeCustomerAddress(addressId)
      if (response.ok) {
        updateSession({ customer: response.payload })

        error.value = { ...error.value, removeAddress: undefined }
      } else {
        error.value = { ...error.value, removeAddress: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, removeAddress: { __general__: 'error.global.default' } }
      logger.error('useUser.removeAddress', err)
    } finally {
      addressLoading.value = false
    }
  }

  const editAddress = async (params: FirstParameter<typeof api.changeCustomerAddress>) => {
    logger.debug('useCustomer.editAddress', params)

    try {
      addressLoading.value = true

      const response = await api.changeCustomerAddress(params)
      if (response.ok) {
        updateSession({ customer: response.payload })
        error.value = { ...error.value, editAddress: undefined }
      } else {
        error.value = { ...error.value, editAddress: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, editAddress: { __general__: 'error.global.default' } }
      logger.error('useUser.editAddress', err)
    } finally {
      addressLoading.value = false
    }
  }

  const setDefaultAddress = async (params: FirstParameter<typeof api.setDefaultAddress>) => {
    logger.debug('useCustomer.setDefaultAddress', params)

    try {
      addressLoading.value = true

      const response = await api.setDefaultAddress(params)
      if (response.ok) {
        updateSession({ customer: response.payload })
        error.value = { ...error.value, setDefaultAddress: undefined }
      } else {
        error.value = { ...error.value, setDefaultAddress: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, setDefaultAddress: { __general__: 'error.global.default' } }
      logger.error('useUser.setDefaultAddress', err)
    } finally {
      addressLoading.value = false
    }
  }

  const setDefaultSavedCard = async (id: string) => {
    logger.debug('useCustomer.setDefaultSavedCard', id)

    try {
      addressLoading.value = true

      const response = await api.setDefaultSavedCard(id)
      if (response.ok) {
        updateSession({ customer: response.payload })
        error.value = { ...error.value, setDefaultAddress: undefined }
      } else {
        error.value = { ...error.value, setDefaultAddress: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, setDefaultAddress: { __general__: 'error.global.default' } }
      logger.error('useUser.setDefaultAddress', err)
    } finally {
      addressLoading.value = false
    }
  }

  const forgotPassword = async (params: FirstParameter<typeof api.requestResetPasswordToken>) => {
    logger.debug('useCustomer.forgotPassword', params)

    try {
      userLoading.value = true

      const response = await api.requestResetPasswordToken(params)
      if (response.ok) {
        error.value = { ...error.value, forgotPassword: undefined }
      } else {
        error.value = { ...error.value, forgotPassword: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, forgotPassword: { __general__: 'error.global.default' } }
      logger.error('useUser.forgotPassword', err)
    } finally {
      userLoading.value = false
    }
  }

  const checkResetToken = async (params: FirstParameter<typeof api.checkResetToken>) => {
    logger.debug('useCustomer.checkResetToken', params)

    try {
      userLoading.value = true

      const response = await api.checkResetToken(params)
      if (response.ok) {
        checkedResetToken.value = response.payload
        error.value = { ...error.value, checkResetToken: undefined }
      } else {
        error.value = { ...error.value, checkResetToken: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, checkResetToken: { __general__: 'error.global.default' } }
      logger.error('useUser.checkResetToken', err)
    } finally {
      userLoading.value = false
    }
  }

  const setNewPassword = async (params: FirstParameter<typeof api.setNewPassword>) => {
    logger.debug('useCustomer.setNewPassword', params)

    try {
      userLoading.value = true

      const response = await api.setNewPassword(params)
      if (response.ok) {
        error.value = { ...error.value, setNewPassword: undefined }
      } else {
        error.value = { ...error.value, setNewPassword: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, setNewPassword: { __general__: 'error.global.default' } }
      logger.error('useUser.setNewPassword', err)
    } finally {
      userLoading.value = false
    }
  }

  return {
    getDefaultCountry,
    setDefaultSavedCard,
    customer,
    error,
    isAuthenticated,
    userLoading,
    updateUser,
    register,
    registerFromConfirmation,
    changePassword,
    login,
    logout,
    ordersLoading,
    addAddress,
    removeAddress,
    editAddress,
    setDefaultAddress,
    addressLoading,
    forgotPassword,
    checkResetToken,
    setNewPassword,
    checkedResetToken
  }
}
